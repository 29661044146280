import React from "react";
import NavBarLogo from "../media/logo.png";

const Landing = () => {
  return (
    <div className="container-fluid bg-dark px-0 mx-0 vh-100 d-flex justify-content-center align-items-center">
      <div class="h-10 text-white text-center">
        <img src={NavBarLogo} width="180" className="mb-5" />
        <br />
        <a href="https://open.spotify.com/show/0KlJMIhPDf3vVUbjE9QzHC">
          <button className="btn btn-outline-light px-5 py-2 me-3 me-md-4">
            <i className="fas fa-brands fa-spotify me-2" />
            Listen
          </button>
        </a>
        <a href="/home">
          <button className="btn btn-outline-light px-5 py-2">
            <i className="fas fa-newspaper me-2" />
            Read
          </button>
        </a>
      </div>
    </div>
  );
};

export default Landing;
